.timeline-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 6px;
  background-color: lightgray;
  border-radius: 10px;
  /* Platz für das Datum */
}

.timeline-progress {
  background-color: #671b34;
  height: 100%;
  transition: width 0.5s ease;
  border-radius: 10px;
}

.date-label {
  position: absolute;
  color: black;
  font-size: 0.8rem;
  top: 25px;
  /* Position unter dem Balken */
}

.date-label.start {
  left: 0;
  color: grey;
}
.date-label.now {
  left: 45%;
  color: grey;
}
.date-label.end {
  right: 0;
  color: grey;
}
