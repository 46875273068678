.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.my-tooltip-class {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1 !important;
  color: #111827;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: 1000;
  cursor: not-allowed;
}
.tooltip {
  position: absolute;
  background-color: white;
  color: #111827;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  top: -35px; /* Adjust based on your design */
  left: 50%;
  transform: translateX();
  white-space: nowrap;
  z-index: 10;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.customer-map-logo {
  position: absolute;
  background: white;
  height: 160px;
  top: 45px;
  left: 45px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
}

.customer-map-logo img {
  display: block;
  width: auto;
  height: auto;

  max-height: 100%;
  max-width: 350px;
}
