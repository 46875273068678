@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Manrope;
    font-weight: 100;
    src: url(../src/fonts/Manrope-ExtraLight.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 200;
    src: url(../src/fonts/Manrope-ExtraLight.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 300;
    src: url(../src/fonts/Manrope-ExtraLight.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 400;
    src: url(../src/fonts/Manrope-Regular.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 500;
    src: url(../src/fonts/Manrope-Medium.ttf);
  }

  @font-face {
    font-family: Manrope;
    font-weight: 600;
    src: url(../src/fonts/Manrope-SemiBold.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 700;
    src: url(../src/fonts/Manrope-Bold.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 800;
    src: url(../src/fonts/Manrope-ExtraBold.ttf);
  }
  @font-face {
    font-family: Manrope;
    font-weight: 900;
    src: url(../src/fonts/Manrope-ExtraBold.ttf);
  }
}
